import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PageSection from "../../components/page-section"
import FormContainer from "../../components/form/form-container"
import SchoolRegisterSection from "../../components/schools/header-section"
import FormBody from "../../components/form/form-body"
import FormFooter from "../../components/form/form-footer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileSignature, faLocationDot } from "@fortawesome/free-solid-svg-icons"
import { useRef } from "react"
import Swal from 'sweetalert2/dist/sweetalert2.js'
import axios from 'axios'

const SchoolRegisterPage = () => {

  const formElement = useRef<HTMLFormElement>();

  const date1Element = useRef<HTMLInputElement>();
  const date2Element = useRef<HTMLInputElement>();

  const ApiEndpoint = "https://rejsy.waw.pl/api/szkoly/";

  const Api = axios.create();
  Api.defaults.headers.post['Content-Type'] = 'application/json';

  const submitForm = async () => {

    const form = formElement?.current;

    if (form.checkValidity()) {

      form.classList.remove('was-validated');

      // retrieve data from form inputs
      const formInputs = form.getElementsByTagName('input');
      let formDataset = {};
      for (let i = 0; i < formInputs.length; i++) {
        formDataset[formInputs[i].id] = formInputs[i].value;
      }


      const meetingPoint = form.getElementsByTagName('select')[0].value;
      const tourTime = form.getElementsByTagName('select')[1].value;
      const tourAlternativeTime = form.getElementsByTagName('select')[2].value;
      const tourTopic = form.getElementsByTagName('select')[3].value;

      const payload = {
        variant: "addBooking",
        fields: {
          meetingPoint: meetingPoint,
          tourDate: formDataset['tourDate'],
          tourTime: tourTime,
          tourAlternativeDate: formDataset['tourAlternativeDate'],
          tourAlternativeTime: tourAlternativeTime,
          ticketsAmount: formDataset['ticketsAmount'],
          tourTopic: tourTopic,
          schoolName: formDataset['schoolName'],
          fullName: formDataset['fullName'],
          mail: formDataset['mail'],
          phone: formDataset['phone']
        }
      }
      Swal.fire({
        icon: 'info',
        title: 'Rejestruję Twoje zgłoszenie...',
        text: 'Wysyłamy Twój formularz. Prosimy czekać...',
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      const response = await axios.post(ApiEndpoint, payload);
      const jsonResponse = response.data;

      if (response.status == 200 && jsonResponse?.success) {

        Swal.fire({
          icon: 'success',
          title: 'Pomyślnie zarejestrowano',
          text: 'Dziękujemy za rejestrację. Prosimy oczekiwać na potwierdzenie rejestracji oraz kontakt koordynatora.',
          allowOutsideClick: true,
        })

      } else {
        Swal.fire({
          icon: 'error',
          title: 'Coś poszło nie tak...',
          text: 'Wystąpił błąd podczas rejestracji. Prosimy sprobować ponownie lub zgłosić błąd na szkoly@rejsy.waw.pl',
          allowOutsideClick: true,
        });
      }
      console.log(jsonResponse);

      form.reset();

    } else {

      Swal.fire({
        icon: 'warning',
        title: 'Błędy formularza',
        text: 'Formularz zawiera błędy lub braki. Sprawdź i wyślij ponownie',
        allowOutsideClick: true,
      });
      form.classList.add('was-validated');

    }
  }

  return (
    <Layout>
      <Seo title="Rejsy dla szkół - Rejestracja" />
      <SchoolRegisterSection />
      <PageSection className="bg-white">
        <FormContainer title={"Formularz rejestracji"}>
          <FormBody>
            <form ref={formElement} className="row g-3 needs-validation" noValidate>

              <label htmlFor="meetingPoint" className="col-form-label col-sm-4 text-muted">Preferowane miejsce startu</label>
              <div className="col-sm-8">
                  <select className="form-select form-control" aria-label="Wybierz miejsce" aria-describedby="meetingPointHelp" required>
                    <option value="" selected>Wybierz miejsce</option>
                    <option value="Dworzec Wodny">Dworzec Wodny</option>
                    <option value="Przystan Mlociny">Przystań Młociny</option>
                    <option value="Plaza Romantyczna">Plaża Romantyczna</option>
                  </select>
                <div id="meetingPointHelp" className="form-text">Rezerwacja rejsów z Plaży Romantycznej możliwa tylko w terminie 8-12 sierpnia.</div>
              </div>

              <hr className="mt-5 mb-4"></hr>

              <p>Rejsy odbywać się będą w lipcu od poniedziałku do piątku z Dworca Wodnego Warszawa lub Przystani Młociny oraz w dniach 8-12 sierpnia z Plaży Romantycznej.</p>

              <label htmlFor="tourDate" className="col-form-label col-sm-4 text-muted">Preferowany termin i pora rejsów</label>
              <div className="col-sm-8">
                <div className="input-group">
                  <input ref={date1Element} value={null} type="date" className="form-control" id="tourDate" placeholder="Dzień" required />
                  <select id="tourTime" className="form-select form-control" aria-label="Wybierz porę" aria-describedby="tourTimeHelp" required>
                    <option value="" selected>Wybierz porę</option>
                    <option value="dowolna">godz. dowolna</option>
                    <option value="przed 11">do 11:00</option>
                    <option value="11-13">11:00-13:00</option>
                    <option value="po 13">od 13:00</option>
                  </select>
                </div>
                <div id="tourTimeHelp" className="form-text">Rejs trwa 1 godzinę wliczając czynności przygotowawcze do rejsu.</div>
              </div>

              <label htmlFor="tourAlternativeDate" className="col-form-label col-sm-4 text-muted">Alternatywny termin i pora rejsów</label>
              <div className="col-sm-8">
                <div className="input-group">
                  <input ref={date2Element} value={null} type="date" className="form-control" id="tourAlternativeDate" placeholder="Dzień" required />
                  <select id="tourAlternativeTime" className="form-select form-control" aria-label="Wybierz porę" aria-describedby="tourAlternativeTimeHelp" required>
                    <option value="" selected>Wybierz porę</option>
                    <option value="dowolna">godz. dowolna</option>
                    <option value="przed 11">do 11:00</option>
                    <option value="11-13">11:00-13:00</option>
                    <option value="po 13">od 13:00</option>
                  </select>
                </div>
                <div id="tourAlternativeTimeHelp" className="form-text">Alternatywny termin i pora resjów w przypadku gdyby preferowany termin był już zajęty</div>
              </div>

              <hr className="mt-5 mb-4"></hr>

              <label htmlFor="ticketsAmount" className="col-form-label col-sm-4 text-muted">Liczba uczestników</label>
              <div className="col-sm-8">
                <input onChange={null} type="number" className="form-control" id="ticketsAmount" placeholder="" aria-describedby="ticketsAmountHelp" min={2} required />
                <div className="invalid-feedback">
                  Proszę uzupełnić wymagane pole.
                </div>
                <div id="ticketsAmountHelp" className="form-text">1 rejs to 11 uczestników wliczając w to opiekunów.( wpisując 20 osób uznajemy to za chęć zorganizowania 2 rejsów pod rząd)</div>
              </div>

              <label htmlFor="tourTopic" className="col-form-label col-sm-4 text-muted">Tematyka rejsów</label>
              <div className="col-sm-8">
                <select className="form-select form-control" aria-label="Wybierz tematykę" aria-describedby="tourTopicHelp" required>
                  <option value="" selected>Wybierz tematykę</option>
                  <option value="Wiślane legendy">Wiślane legendy</option>
                  <option value="Wisła w historii Polski">Wisła w historii Polski</option>
                </select>
                <div id="tourTopicHelp" className="form-text">W każdym rejsie bierze udział przewodnik, który opowie w wybranej tematyce</div>
              </div>

              <label htmlFor="schoolName" className="col-form-label col-sm-4 text-muted ">Szkoła / instytucja</label>
              <div className="col-sm-8">
                <input type="text" className="form-control" id="schoolName" placeholder="" aria-describedby="schoolNameHelp" required />
                <div className="invalid-feedback">
                  Proszę uzupełnić wymagane pole.
                </div>
                <div id="schoolNameHelp" className="form-text">Pełna nazwa placówki</div>
              </div>

              <hr className="mt-5 mb-4"></hr>

              <label htmlFor="fullName" className="col-form-label col-sm-4 text-muted ">Imię i Nazwisko</label>
              <div className="col-sm-8">
                <input type="text" className="form-control" id="fullName" placeholder="" aria-describedby="fullNameHelp" required />
                <div className="invalid-feedback">
                  Proszę uzupełnić wymagane pole.
                </div>
                <div id="fullNameHelp" className="form-text">Dane osoby rejestrującej</div>
              </div>

              <label htmlFor="mail" className="col-form-label col-sm-4 text-muted ">Email</label>
              <div className="col-sm-8">
                <input type="email" className="form-control" id="mail" placeholder="" aria-describedby="mailHelp" required />
                <div className="invalid-feedback">
                  Proszę uzupełnić wymagane pole.
                </div>
                <div id="mailHelp" className="form-text">Na ten adres wyślemy potwierdzenie rejestracji</div>
              </div>

              <label htmlFor="phone" className="col-form-label col-sm-4 text-muted">Telefon</label>
              <div className="col-sm-8">
                <input type="text" className="form-control" id="phone" placeholder="" aria-describedby="phoneHelp" required />
                <div className="invalid-feedback">
                  Proszę uzupełnić wymagane pole.
                </div>
                <div id="phoneHelp" className="form-text">Numer na który oddzwoni nasz koordynator</div>
              </div>

              <hr className="my-4"></hr>

              <label htmlFor="legal" className="col-form-label col-sm-4 text-muted">Zgody</label>
              <div className="col-sm-8">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" value="" id="check1" required />
                  <label className="form-check-label" htmlFor="check1">
                    Akceptuję <a target="_blank" href="/szkoly/regulamin">regulamin rejsów dla szkół</a>
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" value="" id="check2" required />
                  <label className="form-check-label" htmlFor="check2">
                    Akceptuję <a target="_blank" href="/polityka-prywatnosci">politykę prywatności</a> i zgadzam się na przetwarzanie moich danych osobowych
                  </label>
                </div>
              </div>

            </form>
          </FormBody>
          <FormFooter>
            <div className="container-fluid p-0">
              <div className="row align-items-center">
                <button onClick={submitForm} type="button" className="btn btn-secondary btn-lg"><FontAwesomeIcon icon={faFileSignature} className="pe-2" />Rejestruj szkołę</button>
              </div>
            </div>
          </FormFooter>

        </FormContainer>
      </PageSection>
    </Layout>
  )
}

export default SchoolRegisterPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;