import * as React from "react"
import PageSection from "../page-section"

const SchoolRegisterSection = () => {
    return (
        <PageSection>
            <h1 className="pb-3">Rejestracja do cyklu bezpłatnych rejsów "Lato w mieście"</h1>
            <p>
                Prosimy o wypełnienie formularza rejestracji. Potwierdzenie rejestracji otrzymasz mailem.
            </p>
            <p>
                Po wysłaniu formularza, koordynator skontaktuje się z uczestnikami do 3 dni roboczych, aby potwierdzić termin i rezerwację. (Organizator zastrzega sobie wydłużony czas potwierdzenia rezerwacji w pierwszym tygodniu otwartych rezerwacji tj. do 1 czerwca)
            </p>
            <h4 className="py-3">Kto może uczestniczyć w programie</h4>
            <p>
                Rejsy skierowane są do uczestników stołeczenej Akcji Lato w mieście (uczniowie do 6 klasy szkoły podstawowej wraz z opiekunem).
            </p>
            <h4 className="py-3">Rejsy</h4>
            <p>
                Szkoła może zgłosić dowolną liczbę uczestników.
                Każdy rejs trwa 1 godzinę. W rejsie może wziąć udział do 11 uczestników (w tym opiekun grupy).
            </p>
        </PageSection>
    )
}

export default SchoolRegisterSection